@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?w7aihp');
  src: url('../fonts/icomoon.eot?w7aihp#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?w7aihp') format('truetype'),
    url('../fonts/icomoon.woff?w7aihp') format('woff'), url('../fonts/icomoon.svg?w7aihp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  display: inline-block;
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: top;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-correo:before {
  content: '\e93c';
}
.icon-abajo:before {
  content: '\e900';
}
.icon-abajo-der:before {
  content: '\e901';
}
.icon-abajo-izq:before {
  content: '\e902';
}
.icon-abc-asc:before {
  content: '\e903';
}
.icon-abc-des:before {
  content: '\e904';
}
.icon-accesibilidad:before {
  content: '\e905';
}
.icon-acerca:before {
  content: '\e906';
}
.icon-acerca-apeiron:before {
  content: '\e907';
}
.icon-actualizar:before {
  content: '\e908';
}
.icon-adelantar:before {
  content: '\e909';
}
.icon-admin-solicitud:before {
  content: '\e9a1';
}
.icon-anterior:before {
  content: '\e90a';
}
.icon-aportaciones:before {
  content: '\e90b';
}
.icon-archivo:before {
  content: '\e90c';
}
.icon-arriba:before {
  content: '\e90d';
}
.icon-arriba-der:before {
  content: '\e90e';
}
.icon-arriba-izq:before {
  content: '\e90f';
}
.icon-ascendente:before {
  content: '\e910';
}
.icon-aviso:before {
  content: '\e9a2';
}
.icon-ayuda:before {
  content: '\e911';
}
.icon-buscar:before {
  content: '\e912';
}
.icon-caja:before {
  content: '\e913';
}
.icon-calendario:before {
  content: '\e914';
}
.icon-calificacion:before {
  content: '\e915';
}
.icon-camara:before {
  content: '\e916';
}
.icon-cargar:before {
  content: '\e917';
}
.icon-centrar-mapa:before {
  content: '\e918';
}
.icon-cerrar:before {
  content: '\e919';
}
.icon-check:before {
  content: '\e91a';
}
.icon-check-caja:before {
  content: '\e91b';
}
.icon-check-circulo:before {
  content: '\e91c';
}
.icon-check-neg:before {
  content: '\e91d';
}
.icon-colapsar:before {
  content: '\e91e';
}
.icon-colapsar-abajo:before {
  content: '\e91f';
}
.icon-colapsar-arriba:before {
  content: '\e920';
}
.icon-colapsar-der:before {
  content: '\e921';
}
.icon-colapsar-izq:before {
  content: '\e922';
}
.icon-comunidades:before {
  content: '\e923';
}
.icon-configuracion:before {
  content: '\e924';
}
.icon-convocatoria:before {
  content: '\e9a3';
}
.icon-copiar:before {
  content: '\e925';
}
.icon-correo-nuevo:before {
  content: '\e926';
}
.icon-curso:before {
  content: '\e927';
}
.icon-cursos:before {
  content: '\e9a4';
}
.icon-datos-gen:before {
  content: '\e928';
}
.icon-datos-gen-apeiron:before {
  content: '\e9a5';
}
.icon-derecha:before {
  content: '\e929';
}
.icon-descargar:before {
  content: '\e92a';
}
.icon-descendente:before {
  content: '\e92b';
}
.icon-desplegar:before {
  content: '\e92c';
}
.icon-detener:before {
  content: '\e92d';
}
.icon-diploma:before {
  content: '\e92e';
}
.icon-doc:before {
  content: '\e92f';
}
.icon-editar:before {
  content: '\e930';
}
.icon-educacion:before {
  content: '\e931';
}
.icon-eliminar:before {
  content: '\e932';
}
.icon-eliminar-perfil:before {
  content: '\e933';
}
.icon-eliminar-perfil-apeiron:before {
  content: '\e9a6';
}
.icon-empleo:before {
  content: '\e934';
}
.icon-enlace:before {
  content: '\e935';
}
.icon-enlace-2:before {
  content: '\e9a7';
}
.icon-entrar:before {
  content: '\e936';
}
.icon-escribir:before {
  content: '\e937';
}
.icon-evaluacion-2:before {
  content: '\e938';
}
.icon-evaluacion:before {
  content: '\e939';
}
.icon-expandir:before {
  content: '\e93a';
}
.icon-guardar:before {
  content: '\e93b';
}
.icon-idioma:before {
  content: '\e93e';
}
.icon-imagen:before {
  content: '\e93f';
}
.icon-imprimir:before {
  content: '\e940';
}
.icon-info:before {
  content: '\e941';
}
.icon-inicio:before {
  content: '\e942';
}
.icon-invertir:before {
  content: '\e943';
}
.icon-izquierda:before {
  content: '\e944';
}
.icon-libro:before {
  content: '\e945';
}
.icon-mas:before {
  content: '\e946';
}
.icon-me-gusta:before {
  content: '\e947';
}
.icon-menos:before {
  content: '\e948';
}
.icon-mensaje:before {
  content: '\e9a8';
}
.icon-mensajes:before {
  content: '\e9a9';
}
.icon-menu:before {
  content: '\e949';
}
.icon-menu-puntos:before {
  content: '\e94a';
}
.icon-mexico:before {
  content: '\e94b';
}
.icon-no-me-gusta:before {
  content: '\e94c';
}
.icon-ocultar:before {
  content: '\e94d';
}
.icon-pausa:before {
  content: '\e94e';
}
.icon-pdf:before {
  content: '\e94f';
}
.icon-ppt:before {
  content: '\e950';
}
.icon-premio:before {
  content: '\e951';
}
.icon-regresar:before {
  content: '\e952';
}
.icon-reproducir:before {
  content: '\e953';
}
.icon-retraer:before {
  content: '\e954';
}
.icon-revision:before {
  content: '\e9b1';
}
.icon-siguiente:before {
  content: '\e955';
}
.icon-solicitud:before {
  content: '\e9b2';
}
.icon-soluciones:before {
  content: '\e9b3';
}
.icon-telefono:before {
  content: '\e9b4';
}
.icon-telefono-b:before {
  content: '\e9b5';
}
.icon-tesis:before {
  content: '\e956';
}
.icon-tiempo:before {
  content: '\e957';
}
.icon-ubicacion:before {
  content: '\e958';
}
.icon-ubicacion-pin:before {
  content: '\e959';
}
.icon-usuario:before {
  content: '\e95a';
}
.icon-ver:before {
  content: '\e95b';
}
