
[v-cloak] {
  display: none;
}
.w-px {
  width: 1px;
}
.absolute {
  position: absolute;
}
.overflow-hidden {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}
.opacity-0 {
  opacity: 0;
}
.h-px {
  height: 1px;
}
.p-12 {
  padding: 1rem;
}
.border-gray-300 {
  border-color: #e2e8f0;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-felx {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.left-felx {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    text-align: left;
    margin: 15px;
  }
  .bold-600 {
    font-weight: 600 !important;
  }
}
.icon-archivo {
  font-size: 3.1rem !important;
}
