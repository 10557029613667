@import 'rizoma-theme';
// @import '~@conacyt/rizoma-theme/dist/scss/rizoma-theme';

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../images/jhipster_family_member_0.svg') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../images/jhipster_family_member_0.svg') no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid $success;
}

.invalid:not(form) {
  border-left: 5px solid $danger;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: $danger;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}
@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/****** TABLE - Schedule / Fixed positions ******/
table.table-schedule {
  border-collapse: separate;
  border-spacing: 0;
}
.table-schedule td:last-child,
.table-schedule th:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: white;
  text-align: center;
}
.table-schedule thead tr th {
  top: 0;
}
.table-schedule th:first-child,
.table-schedule th:last-child {
  z-index: 2;
  // background-color: $secondary;
}
/****** Hover ******/
.table-schedule {
  background-color: #fff;
}
.table-schedule tbody tr:hover,
.table-schedule tbody tr:hover td:first-child,
.table-schedule tbody tr:hover td:last-child {
  background-color: $gray-100 !important;
}
.text-primary {
  color: $primary !important;
}

a {
  &.fw-bold {
    font-weight: bold !important;
  }
}
.btn-link {
  color: $primary !important;
  text-underline-offset: 0.3em;
}
.table-schedule {
  .table-info {
    td {
      &:last-child {
        // background-color: #bee5eb !important;
      }
    }
  }
  .table-secondary {
    td {
      &:last-child {
        // background-color: #d6d8db !important;
      }
    }
  }
  .table-dark {
    td {
      &:last-child {
        background-color: #c6c8ca !important;
      }
    }
  }
}
.custom-dropdown {
  margin-left: 15px;
  .btn {
    padding: 0px !important;
  }
  .dropdown-menu {
    &.show {
      position: fixed !important;
    }
  }
  .badge {
    float: right;
  }
}
.b-table-details {
  .card-body {
    padding: 0.5rem;
  }
  .card-title {
    font-size: 0.9rem;
  }
}
.table-primary {
  td {
    background-color: #b8daff !important;
  }
}
.table-danger {
  td {
    background-color: #f5c6cb !important;
  }
}
.table-warning {
  td {
    background-color: #ffeeba !important;
  }
}
.table-success {
  td {
    background-color: #c3e6cb !important;
  }
}
.table-info {
  td {
    background-color: #bee5eb !important;
  }
}
.popover {
  .popover-body {
    ul {
      &.subitemsPopover {
        .dropdown-item {
          white-space: normal !important;
        }
      }
    }
  }
}
.sidebar {
  .item-text {
    overflow: hidden;
  }
  .subitems {
    li {
      .dropdown-item {
        white-space: normal !important;
      }
    }
  }
  .collapse {
    .subitems {
      background-color: #eee !important;
    }
    .subitems-2 {
      background-color: #e7e7e7 !important;
    }
    .subitems-3 {
      background-color: #f3f3f3 !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  #sidebar-wrapper {
    display: none;
  }
  #app {
    width: 100%;
    padding-left: 0px;
  }
}

.form-group,
fieldset {
  label:first-child,
  legend:first-child {
    font-weight: 600 !important;
  }
}
.card {
  border: none !important;
  @include b-shadow;
  border-radius: 1.5rem !important;
}
.label-hidden {
  height: 36px;
  display: block;
}
.m-h3 {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.label {
  font-weight: 600 !important;
}
.inline {
  display: inline !important;
}
.multiselect-tags {
  label {
    margin: 0px !important;
  }
  .msl-multi-select {
    display: flex;
    flex-direction: column-reverse;
    .msl-search-list-input,
    .msl-multi-select__actions {
      display: none !important;
      margin: 10px;
    }
    .msl-searchable-list__no-item {
      display: none;
    }
    .msl-searchable-list__items {
      margin-top: 10px;
      display: block;
      .msl-searchable-list__item {
        cursor: pointer;
        display: inline-block !important;
        margin: 0 10px 5px 0 !important;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        background-color: #fff !important;
        color: #404041 !important;
        border-color: #7c8faf !important;
        border-radius: 1.3rem !important;
      }
      .msl-searchable-list__item--added {
        background-color: #7c8faf !important;
        color: #fff !important;
        border-color: #7c8faf !important;
        border-radius: 1.3rem !important;
      }
    }
    .msl-multi-select__selected {
      .msl-searchable-list__item {
        background-color: #7c8faf !important;
        color: #fff !important;
        border-color: #7c8faf !important;
        border-radius: 1.3rem !important;
      }
    }
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $exito !important;
  background-color: $exito !important;
}
.custom-select.is-invalid {
  border-color: $danger !important;
}
.form-control.is-invalid {
  border-color: $danger !important;
}
.form-control.is-valid {
  border-color: $succes !important;
}
.custom-select.is-valid {
  border-color: $succes !important;
}
.invalid-feedback {
  text-transform: lowercase;
}
.invalid-feedback::first-letter {
  text-transform: uppercase !important;
}
.border-primary {
  border-color: $blue-conacyt !important;
}
/* jhipster-needle-scss-add-main JHipster will add new css style */

/* menu-tabbar */
#subitems {
  li {
    .dropdown-item {
      padding: 0.25rem 2rem;
      text-align: left;
      border-bottom: 1px solid rgba(0, 5, 74, 0.075);
      span {
        white-space: normal;
        font-size: 16px;
      }

      &:hover {
        background-color: #001a44;
        color: #fff !important;
        border-bottom: 2px solid #38e109 !important;
      }

      &:focus {
        background-color: #001a44;
        color: #fff !important;
        border-bottom: 2px solid #38e109 !important;
      }

      &.active,
      &:active {
        background-color: #001a44 !important;
        border-bottom: 2px solid #38e109 !important;
        color: #fff !important;
      }
    }
    &.active {
      background-color: $secondary !important;

      .dropdown-item {
        border-left: 4px solid $blue-conacyt !important;
      }
    }
  }
}

@media (max-width: 520px) {
  #subitems li .dropdown-item span {
    font-size: 14px;
  }
  #subitems li .dropdown-item {
    padding: 0.25rem 1rem;
  }
  .btn {
    display: flex;
  }
}
@media (max-width: 338px) {
  #subitems li .dropdown-item span {
    font-size: 12px;
  }
}
.btn-custom-acciones {
  .btn {
    border: 0px solid transparent !important;
  }
}

.page-item.active .page-link {
  background-color: $blue-conacyt !important;
  border-color: $blue-conacyt !important;
  color: white !important;
}

.page-link {
  color: $blue-conacyt !important;
}
.table {
  .thead-dark th {
    background-color: #002663 !important;
    border-color: #002663 !important;
    [class^='icon-'],
    [class*=' icon-'] {
      font-weight: 600;
    }
  }
}
.card-footer {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

//SNI / Pagos
.count {
  h1 {
    color: $headings-color !important;
  }
}
.label-hidden {
  height: 32px;
  display: block;
}
.mpag-2 {
  margin-top: 2.2rem !important;
}

.acciones {
  color: #1553b7;
  font-weight: 800 !important;
}

.pie-tabla {
  font-size: 0.8rem;
  color: #787878;
  font-weight: bold;
}

.sombreado {
  box-shadow: 0px 4px 6px #00266351 !important;
}

.redondeado {
  border-radius: 24px 24px 0px 0px !important;
}
