<template>
  <div class="pantalla">
    <b-overlay show class="cargando">
      <template #overlay>
        <b-spinner style="width: 5rem; height: 5rem" variant="primary"></b-spinner>
        <h5 style="color: white" class="mt-4 text-center" v-text="mensaje"></h5>
      </template>
    </b-overlay>
  </div>
</template>
<script lang="ts" src="./indicador-carga.component.ts"></script>
<style>
.pantalla {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #00163a99 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cargando {
  margin-top: 25%;
  text-align: center;
}
</style>
