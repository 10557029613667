//---------------------------------------------------------------------------------------
// Additions are for extra classes not found in the normal Bootstrap
// WARNING: Do not create alot of new things here, remember always try to tweak and variable
// or override bootstrap css before trying to create something new.
//---------------------------------------------------------------------------------------
// sass-lint:disable force-element-nesting, force-pseudo-nesting

/*
 * RIZOMA CONACYT
 * --------------------------------------------------
*/
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: $headings-color;
}

.bx-header-title {
  position: relative;
  margin-bottom: 3rem;
}

.header-title-line {
  position: absolute;
  background-color: $primary;
  bottom: -0.625rem;
  height: 0.313rem;
  width: 2.5rem;
}

/*
   * NAVBAR
   * --------------------------------------------------
  */

main {
  padding-top: 6rem;
  padding-bottom: 12rem;
  /* Footer height */
}

// Button icon
.btn-icon {
  height: 2.5rem;
  padding: 0.5rem !important;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.btn-round {
  background-color: transparent;
  border: 0;
  border-radius: 50% !important;
  display: inline-block;
}

.btn-icon:hover,
.btn-icon:active {
  background-color: $secondary;
}

.btn-icon .icon {
  color: $primary !important;
}

.btn-icon.disabled,
.btn-icon:disabled,
.btn-icon:disabled:hover,
.btn-icon:disabled:focus,
.btn-icon.disabled:hover,
.btn-icon.disabled:focus {
  cursor: not-allowed;
  background-color: transparent;
  opacity: 0.65;
}

.bx-btn-icn {
  display: inline-flex;
}

/*
  * BUTTON +ADD
  * --------------------------------------------------
  */

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, 0.076, 1)) {
  transition: $property $duration $ease;
}

.btn-add-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: rgba(126, 78, 92, 0.1);
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  &.btn-plus {
    width: 100%;
    height: auto;

    .bx-icon {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $secondary;
      border-radius: 0.25rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        color: white;
        position: absolute;
        background: $primary;
        width: 3rem;
        height: 3rem;
        top: 0;
        left: 0;
      }
    }

    .btn-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 2.25rem;
      margin: 0 0 0 1.85rem;
      color: $primary !important;
      font-weight: 700;
      line-height: 1.6;
      text-align: left;
    }

    .plus {
      position: absolute;
      top: 14px;
      left: 15px;
    }
  }

  &:hover {
    .bx-icon {
      width: 100%;
    }

    .btn-text {
      padding: 0.75rem 2.5rem;
    }
  }
}

.header-conacyt {
  height: 200px;
  background-color: $blue-conacyt;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: 4rem;
  padding: 15px;

  .avatar {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    display: flex;
    align-items: center;

    .avatar_img {
      margin: 0 15px 0px 15px;
    }
  }

  .user_cvu {
    color: $white;
    font-size: 18px;
    font-size: medium;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      display: block;
    }
  }

  .user {
    color: $white !important;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;

    small {
      display: block !important;
      font-size: 18px !important;
      margin: 5px 0;
    }
  }

  .navbar-light {
    .navbar-nav {
      justify-content: flex-end;

      .dropdown-item {
        display: flex;
        align-items: center;
        color: #121212 !important;

        span {
          padding: 0 1rem !important;
        }

        &:hover {
          background-color: $blue-conacyt !important;
        }

        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }
      }

      .navbar-nav {
        flex-direction: row;
      }

      .nav-link {
        color: $primary !important;
        @include all-transition;

        &:hover {
          color: $white !important;
        }

        span {
          padding-left: 0.5rem;
          padding-right: 0.1rem;
        }
      }
    }
  }

  .jumbotron {
    background-color: $white;
    min-height: 135px;
    padding: 0.5rem 0.8rem !important;
    @include b-shadow;
    display: flex;
    align-items: center;

    .container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .medium {
      font-size: 18px;
    }
  }

  .count__box {
    padding: 0 35px;

    &:first-child {
      &::before {
        border-left: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 65px;
      border-left: 2px solid $gray-300; //d4;
      margin-top: 10px;
    }

    .count__number {
      font-size: 60px;
      line-height: 50px;
      font-weight: 300;
      color: $blue-conacyt;
      @include all-transition;
    }

    .count__title {
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 10px;
      color: #121212 !important;
      @include all-transition;
    }

    @media (max-width: 576px) {
      .count__number {
        font-size: 40px;
        line-height: 20px;
        font-weight: 300;
        color: $blue-conacyt;
      }

      .count__title {
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px;
        color: #121212 !important;
      }
    }
  }
}

//Sidebar
.sidebar {
  .nav-link,
  .dropdown-item {
    width: 100% !important;
    border-left: 4px solid $gray-300; //d4;
    padding: 0.5rem 0.8rem !important;
    color: #121212 !important;
    font-weight: 400;
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;

    &:hover {
      background-color: $blue-conacyt !important;
      border-left: 4px solid $exito !important;
      color: $white !important;

      &:before {
        width: 100%;
      }
    }

    a {
      text-decoration: none !important;
    }
  }

  .nav-item {
    .active,
    .router-link-exact-active {
      background-color: $secondary !important;
      border-left: 4px solid $exito !important;
      color: $blue-conacyt !important;
      font-weight: bold;
    }
  }

  ul {
    &.navbar-nav {
      padding: 0;
      margin: 0;
    }
  }

  & .navbar-nav {
    & .nav-item {
      font-weight: 400;
      padding-left: 16px;
    }
  }

  .sidebar__toggled {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 2px;
    text-align: center;
    @include all-transition;

    svg {
      font-size: 25px;
    }

    button {
      background: $gray-200;
      border-color: transparent;
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));

      &:hover {
        background: $secondary !important;
        border-color: transparent !important;
      }

      &:focus {
        border-color: transparent !important;
      }
    }
  }

  .nav-link {
    .item-text {
      padding-left: 12px;
    }
  }

  .subitems {
    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 2.9rem !important;

        &:hover {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &.active,
        &:active {
          background-color: $blue-conacyt !important;
          border-left: 4px solid $exito !important;
          color: $white !important;
        }
      }

      &.active {
        background-color: $secondary !important;

        .dropdown-item {
          border-left: 4px solid $blue-conacyt !important;
        }
      }
    }
  }
  .subitems-2 {
    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 3.9rem !important;

        &:hover {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &.active,
        &:active {
          background-color: $blue-conacyt !important;
          border-left: 4px solid $exito !important;
          color: $white !important;
        }
      }

      &.active {
        background-color: $secondary !important;

        .dropdown-item {
          border-left: 4px solid $blue-conacyt !important;
        }
      }
    }
  }
  .subitems-3 {
    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 4.9rem !important;

        &:hover {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }

        &.active,
        &:active {
          background-color: $blue-conacyt !important;
          border-left: 4px solid $exito !important;
          color: $white !important;
        }
      }

      &.active {
        background-color: $secondary !important;

        .dropdown-item {
          border-left: 4px solid $blue-conacyt !important;
        }
      }
    }
  }
}

#app {
  position: relative;
  min-height: 100vh;
  padding-left: 50px;
  @include all-transition;
}

#app.toggled {
  padding-left: 300px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  top: 56px;
  left: 0px;
  width: 50px;
  height: 95%;
  background-color: $gray-f8;
  background-image: url('../images/background-side.svg');
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: 1px solid $blue-conacyt; //d4;
  @include all-transition;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background-position: bottom;
  background-repeat: no-repeat;

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
  }

  .sidebar_header {
    padding: 7px 20px;
    font-weight: 700;
    text-transform: uppercase;
    background: $blue-conacyt;
    border-left: 8px solid $border-perfil-usuario-sidebar;

    a {
      color: $white !important;
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .when-items {
    align-items: center;
    display: flex;
  }

  .collapsed {
    .when-open {
      display: none !important;
    }

    .when-closed {
      display: inline-flex !important;
    }
  }

  .not-collapsed {
    .when-open {
      display: inline-flex !important;
    }

    .when-closed {
      display: none !important;
    }
  }
}

// #app.toggled #sidebar-wrapper {
//   left: -350px;
//   margin-left: 350px;
//   width: 350px;
// }

#page-content-wrapper {
  width: 100%;
}

/* Sidebar Styles */
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    .sidebar__toggled {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
}

#app.toggled {
  #page-content-wrapper {
    position: absolute;
    margin-right: -50px;
  }

  .sidebar__toggled {
    position: relative;
    top: 0;
    right: 0;
    margin-right: 0;
  }
}

.dropdown-toggle {
  padding-right: 20px;
}

@media (min-width: 768px) {
  #app {
    padding-left: 300px;

    &.toggled {
      padding-left: 50px;
    }
  }

  #sidebar-wrapper {
    width: 350px;

    .hide-toggle {
      @include fadeIn;
    }
  }

  #app.toggled #sidebar-wrapper {
    left: 0px;
    margin-left: 0;
    width: 50px !important;

    .hide-toggle {
      display: none;
    }

    .sidebar_grouper {
      display: none;
    }
  }

  #app.toggled #page-content-wrapper {
    position: relative;
    margin-right: 50px;
    @include all-transition;
  }
}

//menor a 768px sidebar
@media screen and (max-width: 768px) {
  #sidebar-wrapper {
    width: 50px !important;
    // background-color: #121212 !important;

    .sidebar__toggled,
    .sidebar_grouper {
      display: none;
    }

    .hide-toggle {
      @include fadeIn;
    }

    &:hover {
      width: 300px !important;
    }
  }

  #app.toggled {
    padding-left: 50px;
  }
}

.card {
  .card-header {
    background-color: $white;
    border: none;
  }
}

.step-indicator {
  margin: 60px 0px 30px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;

  span {
    display: contents;

    &:last-child {
      div {
        &:last-child {
          display: none;
        }
      }
    }
  }

  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;

    p {
      text-align: center;
      position: absolute;
      top: -35px;
      color: $primary !important;
      font-size: 14px;
      font-weight: bold;
    }

    &.completed .step-icon {
      background: $blue-conacyt;

      &:hover {
        background: $blue-conacyt;
      }
    }

    &.active {
      p {
        color: $blue-conacyt !important;
      }

      .step-icon {
        background: $blue-conacyt;
      }
    }
  }

  .step-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $primary;
    font-size: 10px;
    text-align: center;
    position: relative;
    line-height: 50px;
    font-size: 20px;
    cursor: pointer;
    color: $white !important;
    @include all-transition;

    &:hover {
      background: $blue-conacyt;
      color: $white !important;
    }
  }
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: $primary;
  flex: 1;

  &.active {
    background: $blue-conacyt;
  }

  &.completed {
    background: $blue-conacyt;
  }
}

@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}

/*
   * FOOTER
   * --------------------------------------------------
   */
/* Footer at the bottom */
footer {
  clear: both;
}

.footer {
  padding: 0.57rem 1rem;
  border-top: 1px solid $gray-300; //d4;
  margin-top: 7rem;
  text-align: center;

  .row {
    min-height: 4rem;
  }

  p {
    margin: 0px !important;
  }
}

.popover {
  .popover-header {
    border-radius: calc(0.3rem - 1px);
  }

  .popover-body {
    padding: 0.3rem;

    ul {
      &.subitemsPopover {
        margin-bottom: 0 !important;

        .dropdown-item {
          padding: 0.25rem 0.5rem !important;
          color: #121212 !important;
          @include all-transition;

          &:hover {
            background-color: $secondary;
          }

          &:focus {
            background-color: $secondary;
            color: #212529 !important;
          }
        }

        .active {
          background-color: $secondary !important;
        }
      }
    }
  }
}

.ribbon {
  a {
    color: $white !important;
  }
}

.w-95 {
  width: 95% !important;
}

.form-control,
.custom-select,
.btn,
.border-file {
  @include border-radius(1.3rem !important, 0);
  border: 1px solid;
  border-color: $text-color !important;
}

.btn-primary {
  background-color: $blue-conacyt !important;
  border-color: $blue-conacyt !important;
}

.btn-outline-primary {
  background-color: $white !important;
  color: $outilne-primary-text !important;
  border-color: $outilne-primary !important;
}

.modal-content {
  border-radius: 1.3rem !important;
  border: none !important;

  .modal-header {
    border-radius: 1.3rem 1.3rem 0 0 !important;
    background: $blue-conacyt !important;

    h5 {
      color: $white !important;
    }

    .close {
      color: $white !important;
    }
  }
}
.b-toast {
  .toast-header {
    background-color: $headings-color !important;
    color: $white !important;
    border-radius: 1.1rem 1.1rem 0 0 !important;
    border: 1px;
  }

  .toast {
    background-color: rgb(255 255 255);
    border-radius: 1.3rem !important;
  }

  .close {
    font-size: 1.8rem;
    font-weight: 100 !important;
    line-height: 1;
    color: $white;
    text-shadow: none !important;
    opacity: 1;

    &:hover {
      color: $white;
    }
  }
}
.navbar-dark {
  background-color: $navbar-top !important;

  .navbar-nav {
    .nav-link {
      color: $white !important;

      &:hover {
        color: $exito !important;
      }
    }
  }
}

.progress {
  border-radius: 1rem !important;

  .progress-bar {
    background-color: $blue-conacyt !important;
    border-radius: 1rem !important;
  }
}
.card-text {
  word-break: break-word;
}
.btn-link {
  border: none !important;
  padding: 0 !important;
  font-weight: 700 !important;
}
