<template>
  <b-container class="mb-3">
    <b-row class="d-none d-md-block">
      <b-col>
        <b-nav justified class="timeline-steps small-font">
          <b-nav-item :class="clase.Configuracion">
            <b-row>
              <b-col>
                <b-icon icon="gear" class="icono" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span v-text="$t('timeline.configuracion.nombre')"></span>
              </b-col>
            </b-row>
          </b-nav-item>
          <b-nav-item :class="clase.Prenomina">
            <b-row>
              <b-col>
                <b-icon icon="card-list" class="icono" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span v-text="$t('timeline.prenomina.nombre')"></span>
              </b-col>
            </b-row>
          </b-nav-item>
          <b-nav-item :class="clase.Dispersion">
            <b-row>
              <b-col>
                <b-icon icon="list-check" class="icono" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span v-text="$t('timeline.dispersion.nombre')"></span>
              </b-col>
            </b-row>
          </b-nav-item>
          <b-nav-item :class="clase.Conciliacion">
            <b-row>
              <b-col>
                <b-icon icon="file-check" class="icono" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span v-text="$t('timeline.conciliacion.nombre')"></span>
              </b-col>
            </b-row>
          </b-nav-item>
          <b-nav-item :class="clase.Cierre">
            <b-row>
              <b-col>
                <b-icon icon="door-closed" class="icono" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span v-text="$t('timeline.cierre.nombre')"></span>
              </b-col>
            </b-row>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <b-row class="d-md-none">
      <b-col lg="6" class="d-flex justify-content-center">
        <b-nav vertical>
          <b-nav-item :class="clase.Configuracion">
            <div class="d-flex align-items-center">
              <span class="posicion"><b-icon icon="gear" class="icono mr-2" /></span>
              <span v-text="$t('timeline.configuracion.nombre')"></span>
            </div>
          </b-nav-item>
          <b-nav-item :class="clase.Prenomina">
            <div class="d-flex align-items-center">
              <span class="posicion"><b-icon icon="card-list" class="icono mr-2" /></span>
              <span v-text="$t('timeline.prenomina.nombre')"></span>
            </div>
          </b-nav-item>
          <b-nav-item :class="clase.Dispersion">
            <div class="d-flex align-items-center">
              <span class="posicion"><b-icon icon="list-check" class="icono mr-2" /></span>
              <span v-text="$t('timeline.dispersion.nombre')"></span>
            </div>
          </b-nav-item>
          <b-nav-item :class="clase.Conciliacion">
            <div class="d-flex align-items-center">
              <span class="posicion"><b-icon icon="file-check" class="icono mr-2" /></span>
              <span v-text="$t('timeline.conciliacion.nombre')"></span>
            </div>
          </b-nav-item>
          <b-nav-item :class="clase.Cierre">
            <div class="d-flex align-items-center">
              <span class="posicion"><b-icon icon="door-closed" class="icono mr-2" /></span>
              <span v-text="$t('timeline.cierre.nombre')"></span>
            </div>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts" src="./timeline.component.ts"></script>

<style>
.posicion {
  position: relative;
}

.icono {
  color: #ffffff;
  background-color: #002663;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  border-radius: 4rem;
}

.disabled .icono {
  color: #7f92b0;
  background-color: #ffffff;
  border: 0.2rem solid #7f92b0;
  width: 3rem;
  height: 3rem;
  padding: 0.8rem;
  border-radius: 4rem;
}

.active .icono {
  color: #ffffff;
  background-color: #002663;
  width: 3rem;
  height: 3rem;
  padding: 0.8rem;
  border-radius: 4rem;
  border: 0.2rem solid #65e567;
}

.timeline-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10rem;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(.disabled):not(.active):after {
    content: '';
    display: block;
    border: 0.2rem solid #002663;
    width: 76%;
    position: absolute;
    left: 62%;
    top: 2rem;
  }

  .timeline-steps .timeline-step.disabled:not(:first-child):before {
    content: '';
    display: block;
    border: 0.2rem solid #7f92b0;
    width: 76%;
    position: absolute;
    right: 62%;
    top: 2rem;
  }
}

@media (max-width: 1200px) {
  .timeline-steps .timeline-step.disabled:not(:first-child):before {
    width: 72%;
    right: 64%;
  }
}

@media (max-width: 992px) {
  .small-font {
    font-size: 0.875rem;
  }

  .icono {
    color: #ffffff;
    background-color: #002663;
    width: 2rem;
    height: 2rem;
    padding: 0.6rem;
    border-radius: 3rem;
  }

  .disabled .icono {
    color: #7f92b0;
    background-color: #ffffff;
    border: 0.2rem solid #7f92b0;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    border-radius: 3rem;
  }

  .active .icono {
    color: #ffffff;
    background-color: #002663;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    border-radius: 3rem;
    border: 0.2rem solid #65e567;
  }

  .timeline-steps .timeline-step:not(.disabled):not(.active):after {
    border: 0.15rem solid #002663;
    top: 1.5rem;
  }

  .timeline-steps .timeline-step.disabled:not(:first-child):before {
    border: 0.15rem solid #7f92b0;
    width: 74%;
    right: 63%;
    top: 1.5rem;
  }

  .timeline-step:not(.disabled):not(.active) .posicion::after {
    content: '';
    display: block;
    border: 0.19rem solid #002663;
    position: absolute;
    height: 65%;
    left: 36%;
    top: 90%;
  }

  .timeline-step.disabled:not(:first-child) .posicion::before {
    content: '';
    display: block;
    border: 0.19rem solid #7f92b0;
    height: 65%;
    position: absolute;
    left: 36%;
    bottom: 90%;
  }
}
</style>
