
.container-scroll {
  max-height: 10em;
  overflow: auto;
}

.header-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.header-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}

.header-scroll::-webkit-scrollbar-thumb {
  background: #002663;
  border-radius: 10px;
}

.header-scroll::-webkit-scrollbar-thumb:hover {
  background: #7c8faf;
}
