<template>
  <div>
    <b-container v-if="account">
      <b-row class="mb-3" id="header-2a">
        <b-col cols="7" class="avatar">
          <b-avatar v-if="foto !== ''" :src="foto" size="4rem" class="avatar_img"></b-avatar>
          <b-img v-else v-bind="mainProps" rounded="circle" :alt="account.firstName" class="avatar_img"></b-img>
          <span>{{ account.firstName }} {{ account.lastName }}</span>
        </b-col>
        <b-col cols="5">
          <div class="user_cvu align-middle">
            <span class="font-weight-bold" v-text="$t('global.menu.account.idrizoma', { id: account.email })"
              >ID Rizoma: {{ account.email }}</span
            >
            <span class="font-weight-bold" v-text="$t('global.menu.account.nocvu', { cvu: account.cvu })">No. CVU: {{ account.cvu }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="jumbotron count">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h1 v-text="$t('global.title')"></h1>
                  <h5 v-if="$route.path === '/' || $route.path === '/nominas'" v-text="$t('global.administracion-nominas')"></h5>
                  <h5 v-else-if="$route.path === '/movimientos-programados'" v-text="$t('movimientos.title.programados')"></h5>
                  <h5 v-else v-text="$t('global.sub-title')"></h5>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="container" v-if="$route.path === '/' && !account">
      <h1>
        <span v-text="$t('global.title')">SNI / Pagos </span>
        <small v-text="$t('global.pleaselog')">Please log in to continue.</small>
      </h1>
      <div class="jumbotron count">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 l35">
              <h2 v-text="$t('global.welcome')">Welcome back! to CONACYT</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./data-user.component.ts"></script>

<style>
@media (min-width: 720px) {
  .l35 {
    left: 35px;
  }
}
</style>
