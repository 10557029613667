<template>
  <b-container>
    <b-row
      align-v="center"
      align-h="center"
      class="text-center text-blue-conacyt"
      style="border-radius: 18px; border: 2px solid #002663; padding: 20px 0px 20px 0px; white-space: nowrap; font-size: 15px"
      v-if="nomina"
    >
      <b-col class="d-none d-md-block" cols="auto">
        <span v-text="$t('detalle.clave.nombre')"></span><b class="espacio-normal"> {{ nomina.clave }}</b>
        <span class="icon-info button espacio-normal" id="popover-detalle-nomina"></span>
      </b-col>
      <b-col class="d-none d-md-block" cols="auto">
        <span v-text="$t('detalle.tipo.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.tipo_nomina }}</b>
      </b-col>
      <b-col class="d-none d-md-block" cols="auto">
        <span v-text="$t('detalle.anio.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.anio }}</b>
      </b-col>
      <b-col class="d-none d-md-block" cols="auto">
        <span v-text="$t('detalle.mes.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.mesNombre }}</b>
      </b-col>
      <b-col class="d-none d-md-block" cols="auto">
        <span v-text="$t('detalle.estatus.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.eventoActual.estatus }}</b>
      </b-col>

      <b-col class="d-md-none" md="4" lg="4">
        <span v-text="$t('detalle.clave.nombre')"></span><b class="espacio-normal"> {{ nomina.clave }}</b>
        <span class="icon-info button espacio-normal" id="popover-detalle-nomina-md"></span>
      </b-col>
      <b-col class="d-md-none" md="4" lg="4">
        <span v-text="$t('detalle.tipo.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.tipo_nomina }}</b>
      </b-col>
      <b-col class="d-md-none" md="4" lg="4">
        <span v-text="$t('detalle.mes.nombre')"></span><b class="mr-4"> {{ nomina.mesNombre }}</b>
        <span v-text="$t('detalle.anio.nombre')"></span><b> {{ nomina.anio }}</b>
      </b-col>
      <b-col class="d-md-none" md="4" lg="4">
        <span v-text="$t('detalle.estatus.nombre')" class="espacio"></span><b class="espacio-normal"> {{ nomina.eventoActual.estatus }}</b>
      </b-col>
    </b-row>
    <b-popover target="popover-detalle-nomina" triggers="hover" placement="top">
      <b-row class="text-default">
        <b-col>
          <h6 v-text="$t('detalle.etiquetas.detalle-nomina')"></h6>
        </b-col>
      </b-row>
      <b-row class="text-blue-conacyt">
        <b-col>
          <span v-text="$t('detalle.descripcion.nombre')"></span><b class="espacio-normal"> {{ nomina.descripcion }}</b>
        </b-col>
      </b-row>
    </b-popover>
    <b-popover target="popover-detalle-nomina-md" triggers="hover" placement="top">
      <b-row class="text-default">
        <b-col>
          <h6 v-text="$t('detalle.etiquetas.detalle-nomina')"></h6>
        </b-col>
      </b-row>
      <b-row class="text-blue-conacyt">
        <b-col>
          <span v-text="$t('detalle.descripcion.nombre')"></span><b class="espacio-normal"> {{ nomina.descripcion }}</b>
        </b-col>
      </b-row>
    </b-popover>
  </b-container>
</template>
<script lang="ts" src="./detalle-nomina.component.ts"></script>
<style>
.popover {
  max-width: 480px !important;
  border: 1px solid #002663 !important;
  border-radius: 18px !important;
  padding: 5px !important;
}

.espacio {
  margin-left: 2px;
}

.espacio-normal {
  margin-left: 1px;
}

.text-blue-conacyt {
  color: #002663;
}

@media (max-width: 500px) {
  .popover {
    max-width: 300px !important;
  }
}

@media (max-width: 320px) {
  .text-blue-conacyt {
    white-space: normal !important;
  }
}
</style>
