
.header-conacyt {
  &.rizoma {
    height: 83px !important;
    margin-bottom: 3rem !important;
  }
}
.plr-50 {
  padding: 50px 25px 0 25px;
}

@media (max-width: 320px) {
  .header-conacyt {
    margin-bottom: 8rem;
  }
}
